import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { To, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { QuoteQuestions as QuoteQuestionsLabel } from '../../../enums/quoteQuestions';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { QuoteQuestionsStep } from '../../../types/schema';
import { WhereToAct } from './Questions/whereToAct';
import { DisposeDebris } from './Questions/disposeDebris';
import { RemoveLeaves } from './Questions/removeLeaves';
import { RemoveDebris } from './Questions/removeDebris';
import { AdditionalInstructions } from './Questions/additionalInstructions';
import { Step } from '../../../layout/Step';
import { StyledHeadlineWrapper } from '../AccountAndPayment/styles';
import { BackButton } from '../../../components/BackButton';
import { StepTitle } from '../../../components/StepTitle';
import { lsI18NService } from '../../../service';
import { updateApp } from '../../../store/modules/app/slice';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { BinaryModalWrapper } from '../../../components/BinaryModalWrapper';
import { StyledWrapperLink } from '../Scheduling/styles';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { trackNotLookingForClicked } from '../../../service/segment/trackers';
import { useWwwLead } from '../../../hooks/useWwwLead';
import { StyledScapingLeadsWrapper } from './styles';

const stepId = StepId.QuoteQuestions;

export const QuoteQuestions = () => {
  const theme = useAppTheme();
  const { control } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payloadBuilder } = useTrackPayload(Events.NotLookingForClicked);
  const { landscapingUrl } = useWwwLead();
  const { currentStep, vertical, goToNextStep } = useStepNavigation({ stepId });
  const questions = (currentStep as QuoteQuestionsStep).options?.questions;

  const nextStep = useCallback(() => void navigate(goToNextStep as To), [navigate, goToNextStep]);

  useEffect(() => {
    dispatch(updateApp({ shouldShowAddress: true }));

    return () => {
      dispatch(updateApp({ shouldShowAddress: false }));
    };
  }, [dispatch]);

  const onImNotLooking = (e?: React.MouseEvent) => {
    e?.preventDefault();
    const payload = payloadBuilder();
    payload && trackNotLookingForClicked(payload);
    window.location.href = landscapingUrl;
  };

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StyledHeadlineWrapper>
            <BackButton className="hidden md:block mr-8 md:mb-4 h-fit w-fit" />
            <StepTitle stepId={stepId} vertical={vertical} />
          </StyledHeadlineWrapper>
        </Step.Content>
        <Step.Form className="pb-48">
          {questions?.map((question, key) => {
            const props = { control, key };

            switch (question.label) {
              case QuoteQuestionsLabel.WhereToAct:
                return <WhereToAct {...props} />;
              case QuoteQuestionsLabel.DisposeDebris:
                return <DisposeDebris {...props} />;
              case QuoteQuestionsLabel.RemoveLeaves:
                return <RemoveLeaves {...props} />;
              case QuoteQuestionsLabel.RemoveDebris:
                return <RemoveDebris {...props} />;
              case QuoteQuestionsLabel.AdditionalInstructions:
                return <AdditionalInstructions {...props} />;
              default:
                return null;
            }
          })}

          <Text style={{ textAlign: 'center', color: theme.colors.outline }}>
            After booking, you will be able to upload pictures to help your pro and book additional services.
          </Text>

          <StyledScapingLeadsWrapper>
            <BinaryModalWrapper
              icon="home-alert-outline"
              onConfirm={onImNotLooking}
              description={lsI18NService.t('scheduling.imNotLooking.description')}
            >
              <StyledWrapperLink>
                {lsI18NService.t('scheduling.imNotLooking.label', { vertical: lsI18NService.t(`${vertical}.title`) })}
              </StyledWrapperLink>
            </BinaryModalWrapper>
          </StyledScapingLeadsWrapper>

          <Step.Footer>
            <Step.Action>
              <Button onPress={() => nextStep()} trackID="get-my-quote-cta" testID="get-my-quote-btn" mode="contained">
                {lsI18NService.t('quoteQuestions.getMyQuote')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
