import { Control, FieldValues } from 'react-hook-form';
import { lsI18NService } from '../../../../service';
import { additionalInstructionsQuestion } from '../../../../constants/quoteQuestions';
import { StyledLabel, StyledQuestionWrapper } from '../styles';
import { TextArea } from '@lawnstarter/ls-react-common/atoms';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
}

export const AdditionalInstructions = ({ control }: Props) => {
  const { fields, label } = additionalInstructionsQuestion;
  const { key } = fields[0];

  return (
    <StyledQuestionWrapper>
      <StyledLabel className="mb-4">{lsI18NService.t(`question.${label}.title`)}</StyledLabel>
      <TextArea control={control} name={key} placeholder={lsI18NService.t(`question.${label}.details`)} />
    </StyledQuestionWrapper>
  );
};
