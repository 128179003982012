import { QuoteQuestions, QuoteQuestionsKeys } from '../enums/quoteQuestions';
import { Question } from '../types/questions';

export const whereToActQuestion: Question = {
  label: QuoteQuestions.WhereToAct,
  fields: [
    { key: QuoteQuestionsKeys.FullYard },
    { key: QuoteQuestionsKeys.FrontYard },
    { key: QuoteQuestionsKeys.BackYard },
    { key: QuoteQuestionsKeys.LeftYard },
    { key: QuoteQuestionsKeys.RightYard },
  ],
};

export const disposeDebrisQuestion: Question = {
  label: QuoteQuestions.DisposeDebris,
  fields: [{ key: QuoteQuestionsKeys.DesiredHaulaway }],
};

export const removeLeavesQuestion: Question = {
  label: QuoteQuestions.RemoveLeaves,
  fields: [{ key: QuoteQuestionsKeys.DesiredBedCleanup }],
};

export const removeDebrisQuestion: Question = {
  label: QuoteQuestions.RemoveDebris,
  fields: [
    { key: QuoteQuestionsKeys.HasDryLeaves },
    { key: QuoteQuestionsKeys.HasWetLeaves },
    { key: QuoteQuestionsKeys.HasFallenLimbs },
    { key: QuoteQuestionsKeys.HasCutLimbs },
    { key: QuoteQuestionsKeys.HasRocks },
    { key: QuoteQuestionsKeys.HasMulch },
    { key: QuoteQuestionsKeys.HasPine },
    { key: QuoteQuestionsKeys.HasPetWaste },
    { key: QuoteQuestionsKeys.HasCement },
    { key: QuoteQuestionsKeys.HasAcorns },
    { key: QuoteQuestionsKeys.HasPineCones },
    { key: QuoteQuestionsKeys.HasOther },
    { key: QuoteQuestionsKeys.OtherDebris },
  ],
};

export const additionalInstructionsQuestion: Question = {
  label: QuoteQuestions.AdditionalInstructions,
  fields: [{ key: QuoteQuestionsKeys.Details }],
};
