import { useMemo } from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';

import { useAppTheme } from '@lawnstarter/ls-react-common';
import { CheckboxOptions } from '@lawnstarter/ls-react-common/molecules';
import { TextArea } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../service';
import { removeDebrisQuestion } from '../../../../constants/quoteQuestions';
import { QuoteQuestionsKeys } from '../../../../enums/quoteQuestions';
import { StyledQuestionWrapper } from '../styles';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
}

export const RemoveDebris = ({ control }: Props) => {
  const theme = useAppTheme();
  const watch = useWatch({ control });
  const { fields, label } = removeDebrisQuestion;

  const options = fields
    .filter(({ key }) => key !== QuoteQuestionsKeys.OtherDebris)
    .map(({ key }) => ({
      label: lsI18NService.t(`question.${label}.${key}`),
      value: key,
    }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldShowOtherDebris = useMemo(() => control._formValues[QuoteQuestionsKeys.HasOther], [watch]);

  return (
    <StyledQuestionWrapper className="border-b border-solid pb-6 sm:pb-8 border-graySecondary">
      <CheckboxOptions control={control} options={options} title={lsI18NService.t(`question.${label}.title`)} />
      {/* DependsOn Feature will be implemented on PE-47243 */}

      {shouldShowOtherDebris && (
        <TextArea
          required={shouldShowOtherDebris}
          control={control}
          name={QuoteQuestionsKeys.OtherDebris}
          placeholder={lsI18NService.t(`question.${label}.${QuoteQuestionsKeys.OtherDebris}`)}
          containerStyle={{ marginTop: theme.spacing.s4 }}
        />
      )}
    </StyledQuestionWrapper>
  );
};
