export enum QuoteQuestions {
  WhereToAct = 'whereToAct',
  DisposeDebris = 'disposeDebris',
  RemoveLeaves = 'removeLeaves',
  RemoveDebris = 'removeDebris',
  AdditionalInstructions = 'additionalInstructions',
}

export enum QuoteQuestionsKeys {
  // WhereToAct
  FullYard = 'fullYard',
  FrontYard = 'frontYard',
  BackYard = 'backYard',
  LeftYard = 'leftYard',
  RightYard = 'rightYard',
  // DisposeDebris
  DesiredHaulaway = 'desiredHaulaway',
  // RemoveLeaves
  DesiredBedCleanup = 'desiredBedCleanup',
  // RemoveDebris
  HasDryLeaves = 'hasDryLeaves',
  HasWetLeaves = 'hasWetLeaves',
  HasFallenLimbs = 'hasFallenLimbs',
  HasCutLimbs = 'hasCutLimbs',
  HasRocks = 'hasRocks',
  HasMulch = 'hasMulch',
  HasPine = 'hasPine',
  HasPetWaste = 'hasPetWaste',
  HasCement = 'hasCement',
  HasAcorns = 'hasAcorns',
  HasPineCones = 'hasPineCones',
  OtherDebris = 'otherDebris',
  HasOther = 'hasOther',
  // AdditionalInstructions
  Details = 'details',
}
