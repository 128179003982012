import tw from 'tailwind-styled-components';

export const StyledQuestionWrapper = tw.div`
  mb-6 sm:mb-8
`;

export const StyledSwitchWrapper = tw.div`
  flex justify-between
`;

export const StyledLabel = tw.div`
  text-base font-medium mr-6
`;

export const StyledScapingLeadsWrapper = tw.div`
  my-8 mx-auto
`;
